<template>
  <div class="main" v-title data-title="" @scroll="handleScroll">
    <div class="title">
      {{ configInfo.imageTextSubTitle }}
    </div>
    <div class="otherInfo">
      <div> 
        <span class="author">{{ configInfo.author }}</span>
      <span class="createTime">创建时间：{{ configInfo.createTime }}</span>
      </div>
      <span>阅读量：{{ configInfo.virtualReadCount }}</span>
    </div>
    <div class="test" v-html="imageTextContent"></div>
    <van-popup v-model="show" :style="{'background':'rgba(0,0,0,0)'}">
      <div class="contentbox">
        <van-image :src="popupImage" width="300" height="350"  @click="imageClick"></van-image>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import { redirect_registerurl, config } from "@/config/config";
import queryJssdk from "@/config/jssdk.js";
export default {
  data() {
    return {
      imageUrl: "",
      backImageUrl: "",
      settimeoutImageUrl: "",
      show: false,
      popupImage: "",
      id: "",
      imageTextContent:'',
      configInfo:{}
    };
  },
  watch: {
    show: {
      handler(newVal, oldVal) {
        console.log("新的值:" + newVal);
        console.log("旧的值:" + oldVal);
        console.log("hellow  world");
        if(newVal && !oldVal) {
          window.TuiaSDK.sendExposure(this.id)
        }
      },
    },
  },
  mounted() {
    let url = window.location.href;
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
        queryJssdk(url);
        }
    console.log(window.TuiaSDK, "window.TuiaSDK");
    this.initSdk();
    this.getConfig();
    const _self = this;
    setInterval(() => {
      _self.popupImage = _self.imageUrl;
      _self.show = true;
      _self.id = 462473;
      console.log("这条消息每20秒触发一次");
    }, 20000); // 20000毫秒等于20秒
  },

  methods: {
    
    initSdk() {
      const _self = this;
      // 触底
      window.TuiaSDK.create([
        {
          id: 462475,
          width: 0, // 没有特殊情况，直接写 0 即可
          height: 0, // 没有特殊情况，直接写 0 即可
          autoExposure: true, // 直跳类型写 true，非直跳(展示素材用户点击跳转)写 false
        },
      ]);
      window.TuiaSDK.getMaterial(462475, function (image) {
        console.log(image);
        _self.imageUrl = image;
      });
      // 返回拦截
      window.TuiaSDK.create([
        {
          id: 462474,
          width: 0, // 没有特殊情况，直接写 0 即可
          height: 0, // 没有特殊情况，直接写 0 即可
          autoExposure: true, // 直跳类型写 true，非直跳(展示素材用户点击跳转)写 false
        },
      ]);
      window.TuiaSDK.getMaterial(462474, function (image) {
        console.log(image);
        _self.backImageUrl = image;
      });
      // 定时
      window.TuiaSDK.create([
        {
          id: 462473,
          width: 0, // 没有特殊情况，直接写 0 即可
          height: 0, // 没有特殊情况，直接写 0 即可
          autoExposure: true, // 直跳类型写 true，非直跳(展示素材用户点击跳转)写 false
        },
      ]);
      window.TuiaSDK.getMaterial(462473, function (image) {
        console.log(image);
        _self.settimeoutImageUrl = image;
      });
    },
    async getConfig() {
      const { data } = await this.$apiFetch.get("/imageText/getDetailById/1");
      console.log(data);
      data.data.createTime = data.data.createTime?  data.data.createTime.split(" ")[0] : '';
      const regex = new RegExp('<img', 'gi')
      this.configInfo = data.data
      document.title =data.data.imageTextTitle;
      this.addReadCount(data.data.id)
      this.imageTextContent = data.data.imageTextContent.replace(regex, `<img style="max-width: 100%; height: auto"`)
    },
    async addReadCount(id) {
      await this.$apiFetch.post('imageTextRead/add',{imageTextId:id})
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 当滚动到底部时，距离顶部+高度 === 总高度
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("滚动到了底部");
        this.popupImage = this.settimeoutImageUrl;
        this.show = true;
        this.id = 462475;
        // 在这里执行加载更多数据的操作
      }
    },
    imageClick() {
      window.TuiaSDK.click(this.id);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
 
}

.test {
  width: 100%;
  // background: #000;
  [src] {
    max-width: 100% !important;
      height: auto !important;
  }
}
.contentbox {
}
.title {
  padding: 0 40px;
  padding-top: 40px;
  font-family: PingFangSC, PingFang SC;
font-weight: bold;
font-size: 38px;
color: #333333;
line-height: 56px;
text-align: left;
font-style: normal;
letter-spacing: 6px;
}
.otherInfo {
  padding: 0 40px;
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  // padding-right: 40px;
  margin-bottom: 40px;
}
.author {
  font-size: 24px;
  color: #006eff;
  margin-right: 40px;
}
.createTime {
  font-size: 24px;
  color: #333;
  margin-right: 40px;
}
</style>
