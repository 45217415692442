import axios from "axios";
import wx from "weixin-js-sdk";
// import apiFetch from './apiKey'
export default function queryJssdk(url) {
  let currentUrl = url.split("#")[0];
  axios({
    url: `/api/weixin/getJsSdkSign`,
    method: "get",
    params: {
      url: currentUrl,
      key: "",
    },
  }).then((res) => {
    if (!res.data.code) {
      let data = res.data;
      wx.config({
        debug: false,
        appId: data.appId,
        timestamp: data.timestamp,
        nonceStr: data.nonStr,
        signature: data.signature,
        jsApiList: [
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
          "chooseImage",
          "previewImage",
          "uploadImage",
          "chooseWXPay",
          "getLocation",
          "openLocation",
          "scanQRCode",
          "playVoice",
          "pauseVoice",
          "stopVoice",
          "startRecord",
          "stopRecord",
          "onVoiceRecordEnd",
          "onVoicePlayEnd",
          "uploadVoice",
          "downloadVoice",
          "updateTimelineShareData",
          "updateAppMessageShareData",
        ],
      });
    }
  });
}
